<template>
  <div class="list cl">
    <div class="list-con">
      <ul v-if="!paginationValue">
        <li v-for="(item, index) in list" :key="index">
          <i></i>
          <span @click="goDetail(item.id,'详情')">
            <!-- <div style="display: inline-block">【{{ item }}】</div> -->
            {{item.title}}
          </span>
          <span>
            <!-- {{conversionTimestamp(item.fabushijian)}} -->
          </span>
        </li>
      </ul>
      <div v-else style="margin-top: 100px;text-align: center">暂无数据</div>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :hide-on-single-page="paginationValue"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { searchAll } from "../api/api";

export default {
  name: "search",
  data() {
    return {
      currentPage: 1,
      total: 0,
      pageSize: 10,
      list: [],
      paginationValue: false
    };
  },
  methods: {
    init() {
      let params = `pageNo=${this.currentPage}&pageSize=${this.pageSize}&str=${this.$route.params.con}`;
      this.getList(params);
    },
    getList(params) {
      searchAll(params).then(res => {
        this.list = res.result.data;
        this.total = res.result.count;
        if (res.result.count !== 0) {
          this.paginationValue = false;
        } else {
          this.paginationValue = true;
        }
      });
    },
    goDetail(id, type) {
      this.$router.push({
        name: "detail",
        params: { id: id, type: type }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let params = `pageNo=${this.currentPage}&pageSize=${this.pageSize}&str=${this.$route.params.con}`;
      this.getList(params);
    },
    conversionTimestamp(timestamp) {
      timestamp = parseInt(timestamp);
      var date = new Date(timestamp);
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      var day = date.getDate();
      day = day < 10 ? "0" + day : day;
      return date.getFullYear() + "-" + month + "-" + day;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    $route() {
       this.currentPage = 1;
      this.init();
     
    }
  }
};
</script>

<style scoped lang="less">
@import "../assets/style/list";
.list-con {
  width: 100%;
}
</style>
